import { Injectable } from '@angular/core';

import { ApiClient } from './apiClient.service';
import { Observable } from 'rxjs';
import { IRestorePasswordRequest } from '../models/IRestorePasswordRequest';
import { IRestorePasswordResponse } from '../models/IRestorePasswordResponse';
import { IUser } from '../models/IUser';
import { IAccessToken } from '../models/IAccessToken';
import { InitResponse, UserDetails } from '../models/init-user.model';

@Injectable()
export class UserService {
    basePath = '/users';

    constructor(private apiClient: ApiClient) {}

    public createUser(user: IUser, invitationToken: string): Observable<IAccessToken> {
        const path = `${this.basePath}/${invitationToken}`;
        return this.apiClient.post(path, user);
    }

    public initUser(userDetails: UserDetails): Observable<InitResponse> {
        const path = `${this.basePath}/init`;
        return this.apiClient.post(path, userDetails);
    }

    public restorePasswordRequest(
        restorePasswordRequest: IRestorePasswordRequest,
    ): Observable<IRestorePasswordRequest> {
        const path = `${this.basePath}/restorePasswordRequest`;
        return this.apiClient.post(path, restorePasswordRequest);
    }

    public restorePasswordResponse(
        restorePasswordResponse: IRestorePasswordResponse,
    ): Observable<IRestorePasswordResponse> {
        const path = `${this.basePath}/restorePasswordResponse`;
        return this.apiClient.post(path, restorePasswordResponse);
    }
}
